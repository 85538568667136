/* src/App.css */

/* Align the profile and uploader horizontally */
.app-container {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: flex-start; /* Align items to the top */
  margin-top: 10px; /* Add some top margin */
  position: relative; /* For the fixed logout button */
}


/* Title of the uploader section */
.title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Input container styling */
.file-input-container {
  margin-bottom: 15px;
}

/* Styling for file input elements */
.file-input {
  display: inline-block;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

/* Upload button styling */
.upload-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Disable upload button when necessary */
.upload-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Error message styling */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

/* Output container styling */
.output-container {
  margin-top: 10px;
}

/* Styling for output title */
.output-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

/* PDF viewer styling */
.pdf-viewer {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* Download button styling */
.download-button {
  padding: 8px 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Hover state for download button */
.download-button:hover {
  background-color: #0056b3;
}

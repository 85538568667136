.jobtitle
{
    display: block;
    border-top: 4px solid #a7a59b;
    background-color: #df7b00;
    height: 22px;
    line-height: 22px;
    padding: 4px 6px;
    font-size: 14px;
    color: #000;
    margin-bottom: 13px;
    clear:both;
}
.jobtitle .duration { float:right }
.jobtitle .name { float:left }